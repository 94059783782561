export const LOGIT_INTERCEPTS = {
    1: -0.5546437377673277,
    2: -2.359839252048484,
    3: -3.404992077713395,
    4: -1.4467278321784671,
    5: -1.818525253843254,
    6: -2.447087895281669,
    7: -2.295140158752002,
    8: -2.9246415215502597,
    9: -3.897498557804419,
    10: -3.5226131595338095,
    11: -3.1107881454459814,
    12: -2.2918376070546373,
    13: -1.627221223723119,
    14: -2.581482120526271,
    15: 0.0,
    16: -1.247180320133051,
    17: -1.0985237385078672,
    18: -2.227995763496908,
    19: -0.5543337886069925,
    20: -1.5501928553641835,
};
export const LOGIT_PFA = {
    1: 0.1490106047193056,
    2: 0.25146214383704374,
    3: 0.23272677651040474,
    4: 0.1865784971695436,
    5: 0.2587187610391455,
    6: 0.28540911423907417,
    7: 0.2393977760177286,
    8: 0.2861804868388846,
    9: 0.367078616365145,
    10: 0.21871249953451566,
    11: 0.16654298243513113,
    12: 0.21905995210032836,
    13: 0.22334789852997,
    14: 0.25541593347572217,
    15: 0.27732226835472457,
    16: 0.18721289980373482,
    17: 0.1616770508568418,
    18: 0.18501702108362045,
    19: 0.26859530576072416,
    20: 0.27659354204125386,
};
export const LOGIT_NFA = {
    1: 0.44762541438605297,
    2: 0.3222995277464653,
    3: 0.28648263865885504,
    4: 0.5340362248932781,
    5: 0.35277813807184527,
    6: 0.38025128934363944,
    7: 0.2870374279228047,
    8: 0.29650053611207894,
    9: 0.2344631739483278,
    10: 0.3722823132063947,
    11: 0.4254822106851578,
    12: 0.46556318453632284,
    13: 0.4712335850712464,
    14: 0.34231894480696473,
    15: 0.49125345995716024,
    16: 0.4291099122868604,
    17: 0.469228678629215,
    18: 0.45800178997404634,
    19: 0.4137535703254643,
    20: 0.37830195427318875,
};
export const LOGIT_IS_POS2 = {
    1: 0.03722917758621472,
    2: 0.08042312081807446,
    3: 0.23030685155951222,
    4: 0.27876234141161166,
    5: 0.21758599727845454,
    6: 0.2189804693831663,
    7: 0.3593456567881659,
    8: -0.002210548117592554,
    9: 0.21055682499403852,
    10: 0.4866148704228948,
    11: 0.5747109126557635,
    12: 0.31433731671022425,
    13: 0.32481714040173904,
    14: 0.25604086081687977,
    15: 0.14181078366319091,
    16: 0.057298647500687125,
    17: 0.08883476806853374,
    18: 0.36752522252724096,
    19: 0.1963342236918312,
    20: 0.07165513093968504,
};
export const LOGIT_IS_POS3 = {
    1: 0.3139014466675337,
    2: 0.4676133582366669,
    3: 0.5679542804061349,
    4: 0.5838726783896472,
    5: 0.5158467496463208,
    6: 0.3484383810537006,
    7: 0.6024099624794509,
    8: 0.21600322716151404,
    9: 0.5288972561881221,
    10: 0.8691226592353127,
    11: 0.6378570801046541,
    12: 0.551533857820964,
    13: 0.5298271348257708,
    14: 0.46173152006136164,
    15: 0.40849446624942315,
    16: 0.3158459522782439,
    17: 0.24561844584368242,
    18: 0.6760682885422824,
    19: 0.517210887190379,
    20: 0.5277270181648286,
};
export const LOGIT_IS_POS4 = {
    1: 0.4807062844152869,
    2: 0.6939207695900529,
    3: 0.7872109818608687,
    4: 0.8663427088720156,
    5: 0.7629709145610081,
    6: 0.6752331099402389,
    7: 0.8446092455746559,
    8: 0.6250688009017855,
    9: 1.0014296053818121,
    10: 1.064691208376414,
    11: 1.1006623986231012,
    12: 0.9793288490008586,
    13: 0.8898840551572553,
    14: 0.71598733194195,
    15: 0.5411416927765224,
    16: 0.684415989013067,
    17: 0.5376139183282331,
    18: 0.9486376732417383,
    19: 0.7471798887076085,
    20: 0.8275503730437301,
};
